import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { weightOptions } from 'constants/weightOptions';
import ScreenReaderAnnouncement from 'components/ScreenReaderAnnouncement';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Success } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { BREAKPOINTS } from 'constants/grid';
import { useParams } from 'react-router-dom';
import { justDeal } from 'components/MealDealPage/Recommendations/utils/getMealDealBuilderMessages';
import styles from './ProductPodCounter.scss';

const ProductPodCounter = ({
  added,
  productName,
  trolleyQuantityAmount,
  trolleyQuantityUom,
  isInMealDeal,
  withBoxShadow,
}) => {
  const { mealDealId } = useParams();
  const isMobile = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.sm });
  const isTablet = useClientSideMediaQuery({ minWidth: BREAKPOINTS.sm, maxWidth: BREAKPOINTS.md });
  const isMobileOrTablet = isMobile || isTablet;

  const displayInMobileOrTabletWithMealDeals = isInMealDeal && isMobileOrTablet;
  const displayInTabletWithMealDeals = isInMealDeal && isTablet;

  const label = `${trolleyQuantityAmount}${weightOptions[trolleyQuantityUom].label}`;
  const screenReaderLabel = `${label} ${productName} in trolley.`;

  return (
    <div
      className={classNames(styles.productPodCounter, {
        [styles.withBoxShadow]: withBoxShadow,
      })}
      data-test="product-pod-counter-text"
    >
      <div className={classNames(styles.initialMessage, { [styles.added]: added })}>
        <Typography styleAs="paragraphHeading">{label} added</Typography>
      </div>
      <div className={styles.mainMessage}>
        <Typography
          styleAs={displayInTabletWithMealDeals ? 'paragraphSmall' : 'paragraphHeading'}
          className={displayInTabletWithMealDeals ? styles.tabletWithMealDeals : null}
          aria-hidden
        >
          {label} in trolley
        </Typography>
      </div>
      <ScreenReaderAnnouncement message={screenReaderLabel} polite />
      {displayInMobileOrTabletWithMealDeals && (
        <div data-testid="inMealDeal" className={styles.inMealDeal}>
          <Success size="small" />
          <Typography
            styleAs={displayInTabletWithMealDeals ? 'paragraphSmall' : 'paragraphHeading'}
          >
            {' '}
            {justDeal(mealDealId) ? 'In deal ' : 'In Meal deal '}
          </Typography>
        </div>
      )}
    </div>
  );
};

ProductPodCounter.propTypes = {
  added: PropTypes.bool,
  trolleyQuantityAmount: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  trolleyQuantityUom: PropTypes.string.isRequired,
  isInMealDeal: PropTypes.bool,
  withBoxShadow: PropTypes.bool,
};

ProductPodCounter.defaultProps = {
  added: false,
  isInMealDeal: false,
  withBoxShadow: false,
};

export default ProductPodCounter;
