import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import {
  AddBold as AddIcon,
  MinusBold as MinusIcon,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { refType } from 'constants/types/ref';

import styles from './index.scss';

const TrolleyButtons = ({
  isRecentlyUpdated,
  isProductInTrolley,
  isEditingTrolleyAmount,
  isDisabled,
  updateInTrolley,
  handleUpdateKeyPress,
  decrementInTrolley,
  incrementInTrolley,
  addToTrolley,
  plusButtonRef,
  addButtonRef,
  productName,
}) => {
  return (
    <>
      <div className={classNames(styles.updateBtn, { [styles.hide]: !isEditingTrolleyAmount })}>
        {isRecentlyUpdated ? (
          <div className={styles.updated}>Updated</div>
        ) : (
          <Button
            aria-label={`update ${productName} in trolley`}
            data-testid="trolleyUpdateButton"
            disabled={isDisabled}
            onClick={updateInTrolley}
            onKeyPress={handleUpdateKeyPress}
            label="Update"
            type="button"
            theme="secondary"
            width="fit"
          />
        )}
      </div>

      <div
        className={classNames(styles.btnCTA, {
          [styles.hide]: isEditingTrolleyAmount || !isProductInTrolley,
        })}
        data-testid="trolleyMinusButtonWrapper"
      >
        <Button
          aria-label={`Remove ${productName} from trolley`}
          data-testid="trolleyMinusButton"
          disabled={isDisabled}
          onClick={decrementInTrolley}
          startIcon={<MinusIcon />}
          type="button"
          theme="primary"
          width="fit"
        />
      </div>

      <div
        className={classNames({
          [styles.addCTA]: !isProductInTrolley,
          [styles.btnCTA]: isProductInTrolley,
          [styles.hide]: isEditingTrolleyAmount,
        })}
        data-testid={isProductInTrolley ? 'trolleyPlusButtonWrapper' : 'addButtonWrapper'}
      >
        <Button
          aria-label={`Add ${productName} to trolley`}
          ref={isProductInTrolley ? plusButtonRef : addButtonRef}
          data-testid={isProductInTrolley ? 'trolleyPlusButton' : 'addButton'}
          disabled={isDisabled}
          onClick={isProductInTrolley ? incrementInTrolley : addToTrolley}
          label={isProductInTrolley ? undefined : 'Add'}
          startIcon={isProductInTrolley ? <AddIcon /> : undefined}
          type="button"
          theme={isProductInTrolley ? 'primary' : 'secondary'}
          width={isProductInTrolley ? 'fit' : 'full'}
        />
      </div>
    </>
  );
};

TrolleyButtons.propTypes = {
  isRecentlyUpdated: PropTypes.bool.isRequired,
  isProductInTrolley: PropTypes.bool.isRequired,
  isEditingTrolleyAmount: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  updateInTrolley: PropTypes.func.isRequired,
  decrementInTrolley: PropTypes.func.isRequired,
  handleUpdateKeyPress: PropTypes.func.isRequired,
  incrementInTrolley: PropTypes.func.isRequired,
  addToTrolley: PropTypes.func.isRequired,
  productName: PropTypes.string.isRequired,
  plusButtonRef: refType,
  addButtonRef: refType,
};

TrolleyButtons.defaultProps = {
  plusButtonRef: undefined,
  addButtonRef: undefined,
};

export default TrolleyButtons;
