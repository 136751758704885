import React from 'react';
import PropTypes from 'prop-types';
import { useMakeSelector } from 'hooks/use-make-selector';
import { makeGetCategoryParentInfoByProductId } from 'redux/modules/entities/selectors/products';

import AnchorLink from 'components/AnchorLink';

import styles from 'components/Product/Favourite/Favourite.scss';
import { dataLayer } from 'analytics/data-layer';

const ViewSimilar = ({ productId }) => {
  const { url } = useMakeSelector(makeGetCategoryParentInfoByProductId, productId);
  const onClick = () => {
    dataLayer.push({ event: 'ViewSimilarLink click', productId });
  };

  return (
    <AnchorLink to={url} className={styles.link} data-testid="view-similar-link" onClick={onClick}>
      View similar
    </AnchorLink>
  );
};
ViewSimilar.defaultProps = {
  productId: '',
};

ViewSimilar.propTypes = {
  productId: PropTypes.string,
};

ViewSimilar.displayName = 'ViewSimilar';

export default ViewSimilar;
