import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { weightOptions } from 'constants/weightOptions';

import { dataAttributesGenerator } from 'utils/data-attribute-generator';

import ProductSize from 'components/Product/Size';
import ProductTags from 'components/ProductTags';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import { getProductLinkProps } from 'utils/productLinkProps';
import { isUserLoggedIn as isUserLoggedInSelector } from 'redux/modules/sessions/selectors';

import styles from './index.scss';

const ProductPodHeader = ({
  fullSize,
  name,
  productId,
  onClick,
  trolleyQuantityAmount: amount,
  trolleyQuantityUom: uom,
  position,
}) => {
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const { componentProps = {}, ...productLinkProps } = useMemo(
    () => getProductLinkProps(isUserLoggedIn, name, productId),
    [isUserLoggedIn, name, productId],
  );

  if (!productId) return null;

  return (
    <header
      data-testid="product-pod-header"
      className={classNames(styles.podHeader, {
        [styles.fullSize]: fullSize,
      })}
    >
      <TextLink
        underline="hover"
        {...productLinkProps}
        {...dataAttributesGenerator({
          actiontype: 'redirect',
          originComponent: 'ProductPod',
          shortDescription: `view product details for ${name}`,
        })}
        to={
          componentProps.to !== undefined
            ? {
                pathname: componentProps.to,
                state: { position },
              }
            : undefined
        }
        onClick={onClick}
        className={styles.nameLink}
      >
        <h2 className={classNames(styles.nameWrapper)} data-testid="product-pod-name">
          <span className={classNames(styles.name, styles.ellipses)} title={name}>
            {name}
          </span>
          <ProductSize additionalClasses={styles.inlineSizeMessage} productId={productId} />
        </h2>
        {amount > 0 && (
          <p className="sr-only" data-testid="trolley-quantity">
            You have {amount}
            {weightOptions[uom].label} of this in your trolley
          </p>
        )}
        <ProductSize additionalClasses={styles.sizeMessage} productId={productId} />
      </TextLink>
      <ProductTags productId={productId} />
    </header>
  );
};

ProductPodHeader.displayName = 'ProductPodHeader';

ProductPodHeader.propTypes = {
  badgeText: PropTypes.string,
  entertaining: PropTypes.bool,
  fullSize: PropTypes.bool,
  name: PropTypes.string,
  productId: PropTypes.string,
  onClick: PropTypes.func,
  searchType: PropTypes.string,
  trolleyQuantityAmount: PropTypes.number,
  trolleyQuantityUom: PropTypes.string,
  position: PropTypes.number,
};

ProductPodHeader.defaultProps = {
  badgeText: undefined,
  entertaining: false,
  fullSize: false,
  name: undefined,
  productId: undefined,
  onClick: () => {},
  searchType: '',
  trolleyQuantityAmount: 0,
  trolleyQuantityUom: undefined,
  position: undefined,
};

export default memo(ProductPodHeader);
